import React from "react";
import { Dispatch } from "redux";
import cx from 'classnames';
import { pure } from 'recompose';
import { useSelector } from "react-redux";
import AppConfig from "../../../utils/AppConfig";
import { getAppConfig } from "../../App/epics/appConfig/appConfig";
import Scrollbar from '../../../view/common/Scrollbar/index';
import { TopBarHeight } from '../../TopBar/constants';
import { getLocaleCookie } from "../../Locale/cookie";
import * as actionTypes from "../actionTypes";
import { leftPanelOnMouseOverAC, leftPanelOnMouseLeaveAC } from '../../App/actionCreators/index';
import styles from './Inserter.css';
import itemConfigsMap, { itemKinds } from '../configs/index';
import type { PanelProps, ComponentsKindsToDisableMap } from "../flowTypes";
import { ShortCut } from "./DroppableCmpShortcut";
import { injectIntl } from "../../../view/intl/index";
import VerticalSpacer from "../../../view/common/VerticalSpacer";
import EPSeparator from "./EPSeparator";
import { Intl } from "../../../view/intl/injectIntl";
import { hasUpdate } from "../configs/hasUpdate";
import * as mouseUtils from "../../../utils/mouse";
import { SectionsKind } from "../configs/constants";
import { SectionShortcut } from "../configs/section";
import {
    getComponentTierDataFromComponentKind,
    checkComponentSubscriptionCompatibility
} from "../../ComponentTierManager/utils";
import getSubscriptionFeatureManager from "../../../getSubscriptionFeatureManager";
import CodeComponentKind from "../../oneweb/Code/kind";
import OnlineShop from '../configs/onlineShop';
import { isTrialSubscription, isEcommerceSubscription } from "../../App/epics/subscriptionData/utils";
import { isPartnerSubdomain } from "../../ComponentTierManager/partnerSubdomainNameUtils";
import { WidgetsBookingsKind } from "../../oneweb/Widgets/Bookings/kind";
import WebshopOnBoardingToolTip,
{ ToolTipTypes as ShopOnBoardingToolTipTypes } from "../../oneweb/WebShop/expandedPanel/WebshopOnBoardingToolTip";
import { addYourShopToolTipSeen } from "../../Tooltip/ids";
import { SHOW_TOOLTIP_FOR_ADD_YOUR_SHOP } from "../../Tooltip/actionTypes";
import { PreferencesState } from "../../Preferences/types";
import { webshopAndProductPageIdsSel } from "../../App/epics/siteSettings/selectorActionTypes";
import { getDAL } from "../../../../dal/index";

const shouldShowBookingWidget = () => {
    const appConfig = AppConfig(getAppConfig());
    const isBookingSupportedLocale = (locale) => ["en_gb", "da", "sv", "nl", "de"].includes(locale);
    const bookingsConfig = appConfig.getOptional('oneWeb.bookings');
    let showBookingWidget = bookingsConfig && isBookingSupportedLocale(getLocaleCookie());

    // check if domain whitelisting is enabled and if the current domain is whitelisted
    const whiteListedDomains = bookingsConfig.getOptional('whitelistedDomains', []);
    if (whiteListedDomains.length > 0) {
        const domain = getDAL().getDomain();
        showBookingWidget = showBookingWidget && whiteListedDomains.includes(domain);
    }

    return showBookingWidget;
};

export type ExtendedPanelContentProps = {
    dispatch: Dispatch,
    componentsKindsToDisableMap: ComponentsKindsToDisableMap,
    intl: Intl,
    subscriptionType: string,
    isDemo?: boolean,
    email?: string,
    subscriptionMetadata?: Record<string, any>,
    preferences: PreferencesState,
}

const Item = (props) => {
    const webshopAndProductPageIds=useSelector(webshopAndProductPageIdsSel);
    const dal = getDAL();
    const [onBoardStatus, setOnBoardStatus]=React.useState({
        paymentStatus: false,
        productStatus: false,
        shipmentStatus: false
    });
    const ref = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (props.itemKind === OnlineShop.kind) {
            const onBoardStatusFulfilled=onBoardStatus.paymentStatus&&onBoardStatus.productStatus&&onBoardStatus.shipmentStatus;
            const webshopOrProductWidgetExists=webshopAndProductPageIds.webshopPageIds.length>0
            ||webshopAndProductPageIds.featuredProductsPageIds.length>0;

            if (!props.preferences.addYourShopToolTipSeen&&!webshopOrProductWidgetExists&&onBoardStatusFulfilled) {
                const clientRect = ref.current && ref.current.getBoundingClientRect();
                const { top = 0, width = 0, height = 0, left = 0 } = clientRect || {};

                const calcToRightAndVCenter = () => {
                    return { x: left + width + 5, y: (top + (height / 2)) - 4 };
                };

                const showaddYourShopToolTipSeen = () => {
                    props.dispatch({
                        type: SHOW_TOOLTIP_FOR_ADD_YOUR_SHOP,
                        payload: {
                            position: calcToRightAndVCenter(),
                            elementDimensions: { top, width: 300, height, left: 100 },
                            id: addYourShopToolTipSeen,
                            customClass: styles.addYourShopToolTipSeen,
                            customHTML: <WebshopOnBoardingToolTip
                                type={ShopOnBoardingToolTipTypes.addYourShopToolTipSeen}
                                dispatch={props.dispatch}
                            />
                        }
                    });
                }
                showaddYourShopToolTipSeen();
            }
        }
    }, [onBoardStatus])

    React.useEffect(() => {
        if (props.itemKind === OnlineShop.kind) {
            const getShopOnBoardStatus = async () => {
                const res=await dal.fetchWebshopOnboardingStatus();
                setOnBoardStatus(res.body)
            }
            getShopOnBoardStatus();
        }
    }, [])

    return (
        <div
            ref={ref}
            className={cx(styles.shortCutContainer, {
                [styles.selectedKind]: (props.selectedKind === props.kind),
                [styles.selectedKindPrev]: (props.index - props.selectedItemIndex === -1) && props.selectedItemIndex > -1,
                [styles.selectedKindNext]: (props.index - props.selectedItemIndex === 1) && props.selectedItemIndex > -1,
            })}
            onClick={(e) => {
                props.dispatch({
                    type: actionTypes.PANEL_ITEM_CLICK,
                    payload: { componentKind: props.itemKind, isComponentCompatible: props.isComponentCompatible }
                });
                e.stopPropagation();
            }}
            onMouseDown={(e) => {
                mouseUtils.onMouseDown(
                    e,
                    () => {
                        props.dispatch({
                            type: actionTypes.PANEL_ITEM_LEFT_MOUSE_DOWN,
                            payload: { componentKind: props.itemKind, isComponentCompatible: props.isComponentCompatible }
                        });
                        e.stopPropagation();
                    },
                    () => null
                );
            }}
        >
            {
                props.kind === SectionsKind ?
                    <SectionShortcut
                        iconClassName={props.iconClassName}
                        text={props.text}
                        kind={props.kind}
                        hasUpdate={hasUpdate(props.kind)}
                    />
                    :
                    <ShortCut
                        iconClassName={props.iconClassName}
                        text={props.text}
                        kind={props.kind}
                        hasUpdate={hasUpdate(props.kind)}
                    />
            }
        </div>
    );
}

const Items = (props) => {
    const { isComponentCompatible, selectedKind, preferences, dispatch } = props;
    let itemKindsUpdated = itemKinds;

    if (!(getSubscriptionFeatureManager().isOnlineShopCmpsAllowed()) || isPartnerSubdomain()) {
        itemKindsUpdated = itemKinds.filter(k => k !== OnlineShop.kind);
    }

    if (!(getSubscriptionFeatureManager().isBoookingsWidgetAllowed() && shouldShowBookingWidget())) {
        itemKindsUpdated = itemKindsUpdated.filter(k => k !== WidgetsBookingsKind);
    }

    const selectedItemIndex = itemKindsUpdated.findIndex(kind => selectedKind === kind);

    return <div className={styles.itemsContainer}>
        {
            [
                ...itemKindsUpdated.map((itemKind, index) => {
                    const {
                        kind,
                        shortCut: {
                            iconClassName,
                            text
                        }
                    } = itemConfigsMap[itemKind];

                    return (
                        <Item
                            key={kind}
                            selectedKind={selectedKind}
                            selectedItemIndex={selectedItemIndex}
                            dispatch={dispatch}
                            itemKind={itemKind}
                            isComponentCompatible={isComponentCompatible}
                            iconClassName={iconClassName}
                            text={text}
                            kind={kind}
                            preferences={preferences}
                            index={index}
                        />
                    );
                }),
                <div className={styles.dummyContainer} key="dummyContainer"> </div>
            ]
        }
    </div>;
};

const Title = injectIntl(({ title, showUpgradeIconInTitle, intl }) => (
    <div className={styles.extendedPanelTitleContainer}>
        <VerticalSpacer y={20} />
        <div className={cx(styles.titleIcon, styles.extendedPanelTitle)}>
            {intl.msgJoint(title)}
            {showUpgradeIconInTitle &&
                <span className={cx(styles.componentTitleIcon, styles.premiumComponentIcon)} />}
        </div>
        <VerticalSpacer y={17} />
        <EPSeparator />
    </div>
));

export default injectIntl(pure(({
    state: {
        browserDimensions,
        isMouseOverPanel,
        componentsKindsToDisableMap,
        extendedPanel: { open, itemKind },
        preferences
    },
    subscriptionType,
    subscriptionMetadata,
    dispatch,
    intl
}: PanelProps) => {
    const
        props: ExtendedPanelContentProps = {
            componentsKindsToDisableMap,
            subscriptionType,
            subscriptionMetadata,
            dispatch,
            preferences,
            intl
        },
        leftPanelMouseOverHandler = () => {
            if (!isMouseOverPanel) {
                dispatch(leftPanelOnMouseOverAC());
            }
        },
        onExtendedPanelClose = (e) => {
            e.stopPropagation();
            dispatch({ type: actionTypes.EXTENDED_PANEL_CLOSE });
        },
        // @ts-ignore
        { title, content: Content } = itemKind ? itemConfigsMap[itemKind].extendedPanelData : {};

    let componentTier, isComponentCompatible;
    if (itemKind) {
        if (itemKind === CodeComponentKind && !isTrialSubscription(subscriptionType)) {
            // Existing users can continue using CODE component as regular
            isComponentCompatible = true;
        } else {
            componentTier = getComponentTierDataFromComponentKind(itemKind);
            isComponentCompatible = checkComponentSubscriptionCompatibility(componentTier.componentTierType, subscriptionType);
        }
    }

    const showUpgradeIconInTitle = !isComponentCompatible ||
        (itemKind === OnlineShop.kind && !isEcommerceSubscription(subscriptionType));

    return (
        <div
            className={cx(styles.panel, { [styles.open]: open })}
            onMouseLeave={() => dispatch(leftPanelOnMouseLeaveAC())}
            onMouseOver={leftPanelMouseOverHandler}
            onMouseEnter={leftPanelMouseOverHandler}
        >
            <div className={styles.panelContainer} onClick={onExtendedPanelClose}>
                <Scrollbar height={browserDimensions.height - TopBarHeight}>
                    <div className={styles.itemsWrapper}>
                        <Items
                            selectedKind={itemKind}
                            subscriptionType={subscriptionType}
                            isComponentCompatible={isComponentCompatible}
                            preferences={preferences}
                            dispatch={dispatch}
                        />
                    </div>
                </Scrollbar>
            </div>
            <div className={cx(styles.extendedPanel, 'extendedPanel')}>
                {
                    open &&
                    <div className={styles.extendedPanelContainer}>
                        {typeof title === "string" ? <Title showUpgradeIconInTitle={showUpgradeIconInTitle} title={title} /> : title}
                        <Scrollbar height="auto">
                            <div className={styles.items}>
                                <Content {...props} />
                            </div>
                        </Scrollbar>
                        <div
                            data-title={intl.msgJoint("msg: common.close {Close}")}
                            data-title-position="right"
                            onClick={onExtendedPanelClose}
                            className={styles.panelCloseBtn}
                        > </div>
                    </div>
                }
            </div>
        </div>
    );
}));
