import { DataSite } from "../../../../../dal/model";
import type { LinkPageInfoSaveResult } from "../dialogs/LinkPageInfoDialog/flowTypes";
import { preparePersistentModel } from "../../../../../dal/model/utils";
import loadSiteLinkPageWithValues from "../utils/loadSiteLinkPageWithValues";
import updateSiteDataSaga from "../../saga/updateSiteDataSaga";
import { closeDialogGen } from "../../../../utils/saga";
import makeResolveWhenFulfilledSaga from "../../../../epics/makeResolveWhenFulfilledSaga";
import { siteDataReceiveOnlyValueActionType } from '../../../App/epics/siteData/valueActionType';
import { LINK_PAGE_INFO_SAVE } from "../actionTypes";

const updateLinkPageSagaWorker = function* (
    site: DataSite,
    input: LinkPageInfoSaveResult
): Generator<any, void, any> {
    const
        { linkPageId, data } = input,
        newSite = preparePersistentModel(new DataSite(site)), // clone site
        newLinkPage = newSite.getItemById(linkPageId);
    loadSiteLinkPageWithValues(newLinkPage, data, site);

    yield* updateSiteDataSaga({ site: newSite });

    yield* closeDialogGen();
};

const listener = makeResolveWhenFulfilledSaga(
    siteDataReceiveOnlyValueActionType,
    LINK_PAGE_INFO_SAVE,
    updateLinkPageSagaWorker
);

export {
    listener as default,
    updateLinkPageSagaWorker
};
