import React from 'react';
import { connect } from "react-redux";
import { Flex } from "../../../view/reflexbox/index";
import VerticalSpacer from '../../../view/common/VerticalSpacer';
import Combobox from '../../../view/common/Combobox/index';
import { fontFamilyList } from '../../Fonts/fontsMiddleware';
import PropertyContainer from '../../../view/common/PropertyContainer/index';
import PrevNextButtonGroup from '../IconButtonsGroup/PrevNext';
import styles from './AddGoogleFont.css';
import PropertiesButton from '../../../view/common/Button/PropertiesButton';
import * as fontActions from "../../Fonts/actionTypes";
import LoadingIndicator from '../../../view/common/LoadingIndicator/index';
import binarySearch from '../../../utils/binarySearch';
import injectIntl, { Intl } from "../../../view/intl/injectIntl";
import { GOOGLE_PREFIX } from './constants';
import { saveGoogleFontAC } from "../../Fonts/actionCreators";
import type { AppState } from "../../../redux/modules/flowTypes";
import { fontStatusAppSel } from "../../Fonts/selectors";

const
    comboStyle = { width: '168px' },
    fontsCount = fontFamilyList.length,
    fontsListOptions = fontFamilyList.map(fontFamily => ({
        value: fontFamily,
        label: fontFamily
    })),
    // removed localCompare because doesnt handle spaces correctly;
    stringComparator = (a: string, b: string) => {
        if (a > b) {
            return 1;
        } else if (a < b) {
            return -1;
        }
        return 0;
    },
    SaveFont = "msg: component.font.saveFont {Save font}",
    SavedFont = "msg: component.font.savedFont {Saved font}";

type PropTypes = {
    dispatch: Dispatch;
    intl: Intl,
    comboBoxStyle?: any,
    additionalPayload?: AnyValue,
    fontStatus?: string
};
type State = { fontIdx: number, saveEnabled: boolean };
// $FlowFixme WBTGEN-4051
type OptionType = { label: string, value: string };

class AddGoogleFontClass extends React.Component<PropTypes, State> {
    constructor(props: PropTypes) {
        super(props);
        this.state = {
            fontIdx: -1,
            saveEnabled: false
        };
        this.onNextClicked = this.onNextClicked.bind(this);
        this.onSelectedFontChange = this.onSelectedFontChange.bind(this);
        this.onSaveClicked = this.onSaveClicked.bind(this);
    }

    onNextClicked(isForward?: boolean) {
        const
            currFontIndex = this.state.fontIdx,
            fontIdx = isForward ? ((currFontIndex + 1)%fontsCount) : (currFontIndex <= 0 ? 0 : currFontIndex - 1); // eslint-disable-line

        this.setState({
            fontIdx,
            saveEnabled: true
        });
        // do preview
        this._loadGoogleFontForPreview(fontFamilyList[fontIdx]);
    }

    getIndexOf(font: string) { // eslint-disable-line class-methods-use-this
        return binarySearch(fontFamilyList, font, stringComparator);
    }

    onSelectedFontChange({ value: font }: OptionType) {
        const index = this.getIndexOf(font);
        if (index !== this.state.fontIdx) {
            this.setState({
                fontIdx: index,
                saveEnabled: true
            });
            this._loadGoogleFontForPreview(font);
        }
    }

    _loadGoogleFontForPreview(font) {
        this.props.dispatch({ type: fontActions.LOAD_GOOGLE_FONT_FOR_PREVIEW, payload: GOOGLE_PREFIX + font });
    }

    onSaveClicked() {
        const
            { dispatch, additionalPayload } = this.props,
            { fontIdx } = this.state,
            fontFamily = fontFamilyList[fontIdx];
        dispatch(saveGoogleFontAC(fontFamily, additionalPayload));
        this.setState({
            fontIdx,
            saveEnabled: false
        });
    }

    render() {
        const
            { props: { fontStatus, dispatch, intl }, state: { fontIdx, saveEnabled } } = this,
            fontFamily = fontFamilyList[fontIdx];

        return (
            <div>
                <VerticalSpacer />
                <PropertyContainer label="msg: common.font.chooseFont {Choose font}">
                    <Flex justify="space-between">
                        <Combobox
                            options={fontsListOptions}
                            style={{ ...comboStyle, ...this.props.comboBoxStyle }}
                            value={fontFamily}
                            onChange={this.onSelectedFontChange}
                            matchPos="start"
                            placeholder={intl.msgJoint('msg: common.select.placeholder {Select...}')}
                        />
                        <PrevNextButtonGroup
                            onPrevClicked={() => (fontIdx > 0) && this.onNextClicked()}
                            onNextClicked={() => (fontIdx < fontsCount - 1) && this.onNextClicked(true)}
                            dispatch={dispatch}
                        />
                    </Flex>
                </PropertyContainer>
                <VerticalSpacer />
                <PropertyContainer label="msg: common.font.googleFontPreview {Google Font preview}">
                    <div className={styles.googleFontPreview}>
                        {
                            fontStatus === fontActions.FONT_LOADING_FOR_PREVIEW_FAILED &&
                            <span style={{ color: 'red', fontSize: '10px' }}>
                                {intl.msgJoint(
                                    'msg: component.font.loadFail {Sorry, loading fonts failed. Please refresh your browser window and try again.}' // eslint-disable-line max-len
                                )}
                            </span>
                        }
                        {
                            fontStatus === fontActions.FONT_LOADING_FOR_PREVIEW_IN_PROGRESS &&
                            <LoadingIndicator />
                        }
                        {
                            fontStatus === fontActions.FONT_LOADING_FOR_PREVIEW_SUCCESS &&
                            <span style={{ fontFamily: '"' + fontFamily + '"' }}>{fontFamily}</span>
                        }
                    </div>
                </PropertyContainer>
                <VerticalSpacer y={10} />
                <PropertiesButton
                    text={SaveFont}
                    onClick={this.onSaveClicked}
                    disabled={!saveEnabled}
                    disabledText={fontIdx > -1 ? SavedFont : SaveFont}
                />
                <VerticalSpacer />
                <Flex justify="space-around">
                    <a className={styles.openGoogleFontLink} href="https://fonts.google.com/" target="_blank">
                        {intl.msgJoint('msg: component.font.openGoogleFonts {Open Google Fonts}')}
                    </a>
                </Flex>
            </div>
        );
    }
}

const mapStateToProps = (appState: AppState) => ({
    fontStatus: fontStatusAppSel(appState),
});

export const AddGoogleFont = connect(mapStateToProps)(AddGoogleFontClass);

const
    dialogConfig = {
        id: 'AddGoogleFont',
        title: 'msg: common.addGoogleFont {Add Google Font}',
        height: 285,
        view: injectIntl(AddGoogleFont)
    };

export default dialogConfig;
