import TextComponentKind from '../oneweb/Text/kind';
import { TEXT_FONT_FAMILY_CHANGED } from '../oneweb/Text/actionTypes';

import ContactFormKind from '../oneweb/ContactForm/kind';
import { CONTACT_FORM_FONT_CHANGED } from '../oneweb/ContactForm/actionTypes';
import WebShopComponentKind from "../oneweb/WebShop/kind";
import webshopActionTypes from "../oneweb/WebShop/reducer/actionTypes";

import TableComponentKind from "../oneweb/Table/kind";
import { TABLE_TEXT_FONT_FAMILY_CHANGED } from "../oneweb/Table/actionTypes";

import GalleryComponentKind from "../oneweb/Gallery/kind";
import { GALLERY_PP_CAPTIONS_TITLE_STYLE_FONT_FAMILY_CHANGE } from "../oneweb/Gallery/actionTypes";

import { LOGO_KIND } from "../oneweb/Logo/kind";
import { OPENING_HOURS_KIND } from "../oneweb/OpeningHours/kind";
import { LOGO_PP_TEXT_STYLE_FONT_FAMILY_CHANGED } from "../oneweb/Logo/actionTypes";
import { OPENING_HOURS_FONT_FAMILY_CHANGED } from "../oneweb/OpeningHours/actionTypes";

import { TEXT_LIKE_FONT_FAMILY_CHANGED } from "../oneweb/TextLike/actionTypes";
import { TextLike } from "../oneweb/TextLike/constants";

const
    // FIXME: variants should be managed properly, this is a quick fix to ONEWEB-2115
    variantsCheat = "100,100italic,200,200italic,300,300italic,500,500italic," +
        "600,600italic,700,700italic,800,800italic,900,900italic,italic,regular",
    componentKindToActionMap = {
        [TextComponentKind]: TEXT_FONT_FAMILY_CHANGED,
        [TableComponentKind]: TABLE_TEXT_FONT_FAMILY_CHANGED,
        [WebShopComponentKind]: webshopActionTypes.WEBSHOP_FONT_FAMILY_CHANGED,
        [ContactFormKind]: CONTACT_FORM_FONT_CHANGED,
        [GalleryComponentKind]: GALLERY_PP_CAPTIONS_TITLE_STYLE_FONT_FAMILY_CHANGE,
        [LOGO_KIND]: LOGO_PP_TEXT_STYLE_FONT_FAMILY_CHANGED,
        [TextLike]: TEXT_LIKE_FONT_FAMILY_CHANGED,
        [OPENING_HOURS_KIND]: OPENING_HOURS_FONT_FAMILY_CHANGED
    };

export {
    componentKindToActionMap,
    variantsCheat
};

