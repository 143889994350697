import { FrontClient } from "@one.com/event-tracking-front-client";
import * as sha224 from "crypto-js/sha224";
import { getDAL } from "../../dal";

const singletons = {};

interface TrackingClientFactoryProps {
    adminId?: string;
    email?: string;
    trialEmail?: string;
    trialDomain?: string;
}

export const trackingClientFactory = (factoryProps: TrackingClientFactoryProps): FrontClient => {
    const { trialDomain } = factoryProps;
    const domain = trialDomain || getDAL().getDomain();
    const domainHash = sha224(domain).toString();
    const singletonKey = domainHash;
    if (!singletons[singletonKey]) {
        const serverBaseUrl = `/api/v1/${domain}/track`;
        singletons[singletonKey] = new FrontClient({
            serverBaseUrl,
        });
    }
    return singletons[singletonKey];
};
