import { uniq } from 'ramda';
import * as actionTypes from "./actionTypes";
import WebFont from '../../webfontloader';
import { cloneModel, preparePersistentModel } from "../../../dal/model/utils/index";
import { getFontName } from '../presentational/AddGoogleFont/utils';
import { componentKindToActionMap } from './constants';
import getPageGoogleFonts from "./getPageGoogleFonts";
import { closeDialog } from "../App/actionCreators/index";
import { GOOGLE_PREFIX } from "../presentational/AddGoogleFont/constants";
import { createFontFamilyString, googleFontsAC } from './utils';
import { PAGE_DATASET_LOADED } from '../App/epics/pageDataset/updateReasons';
import { makeActionForwardToSelectedComponent } from '../../redux/forwardTo';
import saveSiteDataFromSagaAction from '../PagesTree/actionCreators/saveSiteDataFromSagaAction';
import pageDataSetVAT from '../App/epics/pageDataset/valueActionType';
import { siteDataValueActionType } from '../App/epics/siteData/valueActionType';
import { UPDATE_SITE_DATA_SAGA_AFTER_SAVE } from '../PagesTree/actionTypes';
import DataSite from '../../../dal/model/DataSite';
import { LoadFamilies, LoadFontsType } from './types';

const
    webFontsList = require('../../../googleWebFontsList.json'),
    fontFamiliesMetadata = {},
    loadedFamilies = {},
    WebFontLoadCallFamilies: Array<Array<string>> = []; // used to load fonts inside iframe of text component

let saveFontAdditionalPayload: any = null;

export const fontFamilyList = webFontsList.items.map(fontMetadata => {
    const { family } = fontMetadata;
    fontFamiliesMetadata[family] = fontMetadata;
    return family;
}, []).sort();

export const loadFontsToIframe = (iframe: HTMLIFrameElement, success: () => any, failure: () => any) => {
    WebFontLoadCallFamilies.forEach(families => {
        WebFont.load({
            google: { families },
            active: success,
            inactive: failure,
            context: iframe
        });
    });
};

const loadFamilies = ({ families, forPreview, dispatch, successAction, failureAction }: LoadFamilies): any => {
    if (families.length > 0) {
        families.forEach(family => {
            loadedFamilies[family] = true;
        });

        // add subset
        const doAllFamiliesHaveLatinSubset = families.every(f => {
                const font = f.split(':')[0];
                // TODO: WBTGEN-19357: remove this line, and uncomment the next one, when WBTGEN-19357 is fixed
                return fontFamiliesMetadata[font] && fontFamiliesMetadata[font].subsets.includes('latin');
                // return fontFamiliesMetadata[font].subsets.includes('latin');
            }),
            successHandler = () => successAction && dispatch(googleFontsAC(successAction)),
            failureHandler = () => failureAction && dispatch(googleFontsAC(failureAction));

        families.push(`&subset=${(forPreview && doAllFamiliesHaveLatinSubset) ? 'latin' : 'all'}`);

        WebFontLoadCallFamilies.push(families);

        WebFont.load({
            google: { families },
            active: successHandler,
            // inactive here, will be called for fonts that don't support latin subset
            // unless, we configure 'text' along with 'families' under 'google'
            // in which case, the text should only be using the characters that the font can support
            // for example, Arabic text for Almarai font family
            //
            // ignoring it, like below, will cause the preview text to display using the fallback font (serif etc)
            // which is the font family, it will actually render with too, when used with unsupported subsets
            inactive: (forPreview && !doAllFamiliesHaveLatinSubset) ? successHandler : failureHandler
        });
    } else if (successAction) {
        dispatch(googleFontsAC(successAction));
    }
};

const loadFonts = ({ fonts, forPreview, dispatch, successAction, failureAction }: LoadFontsType) => {
    const families: Array<string> = [];

    if (fonts && fonts.length) {
        fonts.forEach(font => {
            const fontName = getFontName(font),
                detail = fontFamiliesMetadata[fontName];
            let familyWithVariants = createFontFamilyString(fontName, detail, forPreview);
            if (!loadedFamilies[familyWithVariants]) {
                families.push(familyWithVariants);
            }
        });

        loadFamilies({ families, forPreview, dispatch, successAction, failureAction });
    }
};

export const fontsMiddleware = (store: Store) => (next: Dispatch) => (action: AnyAction) => {
    if (action.epicUpdateReason === PAGE_DATASET_LOADED) {
        const { payload: page } = action,
            siteData: DataSite = store.getState().epics[siteDataValueActionType].state;

        store.dispatch(googleFontsAC(actionTypes.FONTS_LOADING_IN_PROGRESS));
        const fonts = getPageGoogleFonts(page);
        if (fonts.length) {
            loadFonts({
                fonts,
                successAction: actionTypes.FONTS_LOADING_SUCCESS,
                failureAction: actionTypes.FONTS_LOADING_FAILED,
                dispatch: store.dispatch,
            });
        } else {
            store.dispatch(googleFontsAC(actionTypes.NO_PAGE_FONTS_TO_LOAD));
        }

        loadFonts({
            fonts: siteData.fonts,
            forPreview: true,
            dispatch: store.dispatch,
        });
    }

    if (action.type === actionTypes.LOAD_GOOGLE_FONT_FOR_PREVIEW) {
        const { payload: font } = action;
        store.dispatch(googleFontsAC(actionTypes.FONT_LOADING_FOR_PREVIEW_IN_PROGRESS));

        if (font) {
            loadFonts({
                fonts: [font],
                forPreview: true,
                successAction: actionTypes.FONT_LOADING_FOR_PREVIEW_SUCCESS,
                failureAction: actionTypes.FONT_LOADING_FOR_PREVIEW_FAILED,
                dispatch: store.dispatch,
            });
        }
    }

    if (action.type === actionTypes.SAVE_GOOGLE_FONT) {
        const { payload: { googleFont } } = action,
            siteData: DataSite = store.getState().epics[siteDataValueActionType].state,
            pageDataSet = store.getState().epics[pageDataSetVAT].state;

        saveFontAdditionalPayload = action.payload;

        if (siteData && siteData.fonts.indexOf(googleFont) === -1) {
            const
                currentFonts = getPageGoogleFonts(pageDataSet),
                fonts = uniq([...siteData.fonts, ...currentFonts, googleFont]),
                newSiteData = cloneModel(siteData, false /* createNewId */, { fonts }),
                newSite = preparePersistentModel(newSiteData);

            // save site data
            store.dispatch(saveSiteDataFromSagaAction({ site: newSite }, false));
        }
    }

    if (action.type === UPDATE_SITE_DATA_SAGA_AFTER_SAVE && saveFontAdditionalPayload) {
        const { googleFont, additionalPayload }= saveFontAdditionalPayload;

        if (additionalPayload &&
            additionalPayload.source &&
            (
                Object.keys(componentKindToActionMap).indexOf(additionalPayload.source) > -1
                || additionalPayload.source.indexOf('_ACTION') > -1
            )
        ) {
            store.dispatch(
                makeActionForwardToSelectedComponent({
                    type: additionalPayload.actionToDispatch
                        || componentKindToActionMap[additionalPayload.source]
                        || additionalPayload.source,
                    payload: googleFont.replace(GOOGLE_PREFIX, '')
                })
            );
            saveFontAdditionalPayload = null;
        }

        store.dispatch(closeDialog());
    }
    return next(action);
};
