export default {
    TEMPLATE: require('../../../components/oneweb/Template/kind').default,
    BACKGROUND: require('../../../components/oneweb/Background/kind').default,
    HOVERBOX: require('../../../components/oneweb/HoverBox/kind').default,
    IMAGE: require('../../../components/oneweb/Image/kind').default,
    GALLERY: require('../../../components/oneweb/Gallery/kind').default,
    IMAGESLIDER: require('../../../components/oneweb/ImageSlider/kind').default,
    FACEBOOKPAGE: require('../../../components/oneweb/Facebook/kind').default,
    MENU: require('../../../components/oneweb/Menu/kind').default,
    BUTTON: require('../../../components/oneweb/Button/kind').default,
    TEXT: require('../../../components/oneweb/Text/kind').default,
    VIDEO: require('../../../components/oneweb/Video/kind').default,
    VIDEO_FILE: require('../../../components/oneweb/Video/VideoFile/kind').default,
    CONTACTFORM: require('../../../components/oneweb/ContactForm/kind').default,
    DOCUMENT: require('../../../components/oneweb/Document/kind').default,
    STRIP: require('../../../components/oneweb/Strip/kind').default,
    SECTION: require('../../../components/oneweb/Section/kind').default,
    TABLE: require('../../../components/oneweb/Table/kind').default,
    SHAREBUTTONS: require('../../../components/oneweb/ShareButtons/kind').default,
    CODE: require('../../../components/oneweb/Code/kind').default,
    WEBSHOP: require('../../../components/oneweb/WebShop/kind').default,
    WEBSHOP_POLICIES: require('../../../components/oneweb/WebShopMHF/WebShopPolicies/kind').default,
    WEBSHOP_PAYMENT_METHODS: require('../../../components/oneweb/WebShopMHF/WebShopPaymentMethods/kind').default,
    SOCIAL_LINKS: require('../../../components/oneweb/Social/kind').SOCIAL_KIND,
    LOGO: require('../../../components/oneweb/Logo/kind').LOGO_KIND,
    EMAIL_KIND: require('../../../components/oneweb/TextLike/Email/kind').EMAIL_KIND,
    PHONE_KIND: require('../../../components/oneweb/TextLike/Phone/kind').PHONE_KIND,
    ADDRESS_KIND: require('../../../components/oneweb/TextLike/Address/kind').ADDRESS_KIND,
    SVG_KIND: require('../../../components/oneweb/Svg/kind').SVG_KIND,
    FEATURED_PRODUCTS_KIND: require('../../../components/oneweb/FeaturedProducts/kind').default,
    INSTAGRAM_GALLERY: require('../../../components/oneweb/InstagramGallery/kind').default,
    GOOGLE_REVIEWS: require('../../../components/oneweb/GoogleReviews/kind').default,
    FACEBOOK_FEED_GALLERY: require('../../../components/oneweb/FacebookFeedGallery/kind').default,
    OPENING_HOURS: require('../../../components/oneweb/OpeningHours/kind').OPENING_HOURS_KIND,
    PRODUCT_WIDGET: require('../../../components/oneweb/ProductWidget/kind').default,
    FACEBOOK_CHAT: require('../../../components/oneweb/FacebookChat/kind').default,
    WIDGETS_TWITTER: require('../../../components/oneweb/Widgets/Twitter/kind').WidgetsTwitterKind,
    WIDGETS_SPOTIFY: require('../../../components/oneweb/Widgets/Spotify/kind').WidgetsSpotifyKind,
    WIDGETS_PINTEREST: require('../../../components/oneweb/Widgets/Pinterest/kind').WidgetsPinterestKind,
    WIDGETS_VIMEO: require('../../../components/oneweb/Widgets/Vimeo/kind').WidgetsVimeoKind,
    WIDGETS_GOOGLEMAPS: require('../../../components/oneweb/Widgets/GoogleMaps/kind').WidgetsGoogleMapsKind,
    WIDGETS_INSTAGRAM: require('../../../components/oneweb/Widgets/Instagram/kind').WidgetsInstagramKind,
    WIDGETS_FACEBOOK: require('../../../components/oneweb/Widgets/Facebook/kind').WidgetsFacebookKind,
    WIDGETS_SOUNDCLOUD: require('../../../components/oneweb/Widgets/Soundcloud/kind').WidgetsSoundcloudKind,
    WIDGETS_TIKTOK: require('../../../components/oneweb/Widgets/Tiktok/kind').WidgetsTiktokKind,
    WIDGETS_EVENTBRITE: require('../../../components/oneweb/Widgets/Eventbrite/kind').WidgetsEventbriteKind,
    WIDGETS_BINGMAPS: require('../../../components/oneweb/Widgets/BingMaps/kind').WidgetsBingMapsKind,
    WIDGETS_GOFUNDME: require('../../../components/oneweb/Widgets/GoFundMe/kind').WidgetsGoFundMeKind,
    WIDGETS_MAILCHIMP: require('../../../components/oneweb/Widgets/Mailchimp/kind').WidgetsMailchimpKind,
    WIDGETS_PAYPAL: require('../../../components/oneweb/Widgets/Paypal/kind').WidgetsPaypalKind,
    WIDGETS_BOOKINGS: require('../../../components/oneweb/Widgets/Bookings/kind').WidgetsBookingsKind,
};
