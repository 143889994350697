import { variantsCheat } from "./constants";

export const optimizeVariants = (variants) => {
    const
        normals: Array<string | number> = [],
        italics: Array<string | number> = [],
        results: Array<string | number> = [];

    let
        normal,
        italic,
        bold,
        boldItalic;

    // create a list of sizes seperatly for normal and italic.
    variants.forEach(variant => {
        if (variant.match('italic')) {
            italics.push(variant === 'italic' ? 400 : parseInt(variant, 10));
        } else {
            normals.push(variant === 'regular' ? 400 : parseInt(variant, 10));
        }
    });

    // find a good regular weight for normal and italic.
    [400, 300, 500, 200, 600, 100, 700, 800, 900].forEach(size => {
        if (!normal && normals.indexOf(size) !== -1) {
            normal = size;
            results.push(normal === 400 ? "regular" : normal);
        }
        if (!italic && italics.indexOf(size) !== -1) {
            italic = size;
            results.push((italic === 400 ? "" : normal) + "italic");
        }
    });

    // find a good bold weight for normal and italic.
    [700, 800, 600, 900, 500].forEach(size => {
        if (!bold && normal && size > normal && normals.indexOf(size) !== -1) {
            bold = size;
            results.push(bold);
        }
        if (!boldItalic && italic && size > italic && italics.indexOf(size) !== -1) {
            boldItalic = size;
            results.push(boldItalic + "italic");
        }
    });

    return results;
};

export const createFontFamilyString = (font: string, detail: any, forPreview: boolean | undefined) => {
    const fontVariantDetails = detail ? optimizeVariants(detail.variants).join() : variantsCheat;
    const variants = forPreview ? '400' : fontVariantDetails;
    return `${font}:${variants}`;
};

export const googleFontsAC = (action: string) => ({ type: action });
