import { DataSite, DataSectionLink } from "../../../../../dal/model";
import { takePayloadFromDialogGen } from "../../../../utils/saga";
import { NewSectionLinkNameDialogId } from "../../NewPageDialog/newPageDialogIds";
import { NEW_PAGE_LAYOUT_NAME_ENTERED } from "../../NewPageDialog/actions";
import makeResolveWhenFulfilledSaga from "../../../../epics/makeResolveWhenFulfilledSaga";
import { siteDataReceiveOnlyValueActionType } from '../../../App/epics/siteData/valueActionType';
import { SECTION_LINK_DUPLICATE } from "../actionTypes";
import { ROSiteSettingsSelector } from "../../../App/epics/siteSettings/selectorActionTypes";
import { type SiteSettings } from "../../../App/epics/siteSettings/flowTypes";
import { makeSectionLink } from "../../sectionLinkForm/utils";
import { preparePersistentModel } from "../../../../../dal/model/utils";
import updateSiteDataSaga from "../../saga/updateSiteDataSaga";

function insertToSiteMap(items, currentId, newSectionLink) {
    let newItems = [...items];
    for (let i = 0; i < newItems.length; i++) {
        const currItem = newItems[i];
        if (currItem.id === currentId) {
            newItems.splice(i + 1, 0, newSectionLink);
            break;
        } else if (currItem.items && currItem.items.length) {
            currItem.items = insertToSiteMap(currItem.items, currentId, newSectionLink);
        }
    }
    return newItems;
}

const duplicateSectionLinkSagaWorker = function* (
    site: DataSite,
    siteSettings: SiteSettings,
    srcPageRef: DataSectionLink
): Generator<any, void, any> {
    const sectionLinkName = yield* takePayloadFromDialogGen(
        NewSectionLinkNameDialogId,
        NEW_PAGE_LAYOUT_NAME_ENTERED,
        true /* replaceDialog */
    );

    let newSiteData = new DataSite(site);
    const newSectionLink = makeSectionLink({
        pageId: srcPageRef.pageId,
        sectionId: srcPageRef.sectionId,
        name: sectionLinkName,
        hidden: srcPageRef.hidden
    }, site, {});
    newSiteData.folder.items = insertToSiteMap(newSiteData.folder.items, srcPageRef.id, newSectionLink);

    yield* updateSiteDataSaga({ site: preparePersistentModel(newSiteData) });
};

const listener = makeResolveWhenFulfilledSaga(
    siteDataReceiveOnlyValueActionType,
    ROSiteSettingsSelector,
    SECTION_LINK_DUPLICATE,
    duplicateSectionLinkSagaWorker
);

export {
    listener as default,
    duplicateSectionLinkSagaWorker
};
