import { put } from 'redux-saga/effects'; // eslint-disable-line node/file-extension-in-import

import { DataSite } from "../../../../../dal/model";
import type { DataSiteItem } from "../../../../../dal/model/utils/dataSiteItemUtils";
import { isPageRef, isLinkPage } from "../../../../../dal/model/utils/dataSiteItemUtils"; // eslint-disable-line no-duplicate-imports
import {
    DeletePageAndSubPagesDialogId,
    DeleteHomePageAndSubPagesDialogId,
    DeleteLinkPageDialogId,
    DeleteSectionLinkDialogId,
} from "../dialogs/ids";
import { confirmDialogGen, closeDialogGen } from "../../../../utils/saga";
import { PAGE_TREE_PAGE_DELETE_CONFIRM, PAGE_TREE_PAGE_DELETE } from "../actionTypes";
import deletePageFromSite from "../utils/deletePageFromSite";
import updateSiteDataSaga from "../../saga/updateSiteDataSaga";
import type { UpdateSiteDataSagaInput } from "../../saga/updateSiteDataSaga";
import { receiveOnly } from '../../../../epics/makeCondition';
import makeResolveWhenFulfilledSaga from "../../../../epics/makeResolveWhenFulfilledSaga";
import { siteDataReceiveOnlyValueActionType } from '../../../App/epics/siteData/valueActionType';
import currentPageIdValueActionType from '../../../App/epics/currentPageId/valueActionType';
import { resetCurrentPageIdAction } from "../../../App/epics/currentPageId/actionCreators";
import checkUnsavedChangesSaga from "../../../UnsavedChanges/checkUnsavedChangesSaga";

const deletePageSagaWorker = function* (
    site: DataSite,
    currentPageId: string,
    targetPage: DataSiteItem
): Generator<any, void, any> {
    let
        deleteDialogId,
        deleteDialogProps;

    if (isPageRef(targetPage)) {
        // check for unsaved changes when deleting current page
        if (targetPage.pageId === currentPageId) {
            const checked = yield* checkUnsavedChangesSaga();
            if (!checked) return;
        }

        if (site.isHomePageId(targetPage.pageId)) {
            deleteDialogId = DeleteHomePageAndSubPagesDialogId;
        } else {
            deleteDialogId = DeletePageAndSubPagesDialogId;
        }
        deleteDialogProps = { pageName: targetPage.name };
    } else if (isLinkPage(targetPage)) {
        // link page
        deleteDialogId = DeleteLinkPageDialogId;
    } else {
        deleteDialogId = DeleteSectionLinkDialogId;
    }

    yield* confirmDialogGen(deleteDialogId, PAGE_TREE_PAGE_DELETE_CONFIRM, deleteDialogProps);
    yield* closeDialogGen();
    // In case of deletion from section link settings dialog, setting dialog need to be closed
    yield* closeDialogGen();

    // prepare update site map data
    const [newSite, deletedPages] = deletePageFromSite(site, targetPage);

    // define new currentPageId
    let nextCurrentPageId;
    if (isPageRef(targetPage) && site.pageRefContainsPageId(targetPage.id, currentPageId)) {
        if (site.isLastRootPageRef(targetPage.id)) {
            nextCurrentPageId = '';
        } else if (site.homePageId && site.homePageId !== targetPage.pageId) {
            nextCurrentPageId = site.homePageId;
        } else {
            // @ts-ignore
            nextCurrentPageId = newSite.getItems().filter(p => isPageRef(p))[0].pageId;
        }
        // reset current page id before actual deletion to avoid errors in the middle of process
        yield put(resetCurrentPageIdAction(nextCurrentPageId));
    }

    const updateSiteInput: UpdateSiteDataSagaInput = { site: newSite, nextCurrentPageId };
    if (deletedPages) updateSiteInput.deletedPages = deletedPages;
    yield* updateSiteDataSaga(updateSiteInput);
};

const listener = makeResolveWhenFulfilledSaga(
    siteDataReceiveOnlyValueActionType,
    receiveOnly(currentPageIdValueActionType),
    PAGE_TREE_PAGE_DELETE,
    deletePageSagaWorker
);

export {
    listener as default,
    deletePageSagaWorker
};
