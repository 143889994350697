import React from 'react';
import cx from 'classnames';
import Msg from "../../../../view/intl/Msg";
import { EPSeparator } from "../../../Panel/view/EPSeparator";
import { SHOW_WEBSHOP_ADMIN_DIALOG } from "../WebshopAdminDialog/actionTypes";
import { EXTENDED_PANEL_CLOSE } from "../../../Panel/actionTypes";
import { Flex } from "../../../../view/reflexbox/index";
import { PRODUCT_WIDGET_UPDATE_PRODUCT_SETUP_INSERTER_METHOD } from '../../ProductWidget/actionTypes';
import { ExtendedPanelTooltip } from "../../../Tooltip/ids";
import { SHOW_TOOLTIP_FOR_EXTENDED_PANEL, CLOSE_TOOLTIP } from "../../../Tooltip/actionTypes";

import styles from './styles.css';

type Props = {
    dispatch: Function;
    isTrialUser?: boolean;
};

export const SetupProductButton = ({ dispatch, isTrialUser }: Props) => {
    const ref = React.useRef<HTMLButtonElement>(null);

    const [buttonPosition, setButtonPosition] = React.useState<DOMRect | null>(null);

    React.useEffect(() => {
        if (ref && ref.current) {
            const clientRect = ref.current.getBoundingClientRect();
            setButtonPosition(clientRect);
        }
    }, []);

    const calcToRightAndVCenter = ({ top, width, height, left }: ClientRect) => {
        // position relative to the help tip
        return { x: left + width, y: (top + (height / 2)) - 4 };
    };

    const showTooltip = () => {
        if (buttonPosition) {
            dispatch({
                type: SHOW_TOOLTIP_FOR_EXTENDED_PANEL,
                payload: {
                    position: calcToRightAndVCenter(buttonPosition),
                    elementDimensions: buttonPosition,
                    id: ExtendedPanelTooltip,
                    customClass: styles.helpTipContainer,
                    msg: { text: 'msg: webshopInserter.setupProduct.Message'
                    + ' {Start selling directly from your website by adding a product'
                    + 'your visitors can purchase}' },
                }
            });
        }
    };

    const showDisabledTooltip = () => {
        if (buttonPosition) {
            dispatch({
                type: SHOW_TOOLTIP_FOR_EXTENDED_PANEL,
                payload: {
                    position: calcToRightAndVCenter(buttonPosition),
                    elementDimensions: buttonPosition,
                    id: ExtendedPanelTooltip,
                    customClass: styles.helpTipContainer,
                    msg: { text: 'msg: demo.restrictedFeature'
                    + ' {This feature is not available in the trial version.}' },
                }
            });
        }
    };

    const hideTooltip = () => {
        dispatch({ type: CLOSE_TOOLTIP });
    };

    const openSetupShopDialog = (dispatch) => {
        if (!isTrialUser) {
            return () => {
                dispatch({ type: PRODUCT_WIDGET_UPDATE_PRODUCT_SETUP_INSERTER_METHOD });
                dispatch({ type: SHOW_WEBSHOP_ADMIN_DIALOG });
                dispatch({ type: EXTENDED_PANEL_CLOSE });
                dispatch({ type: CLOSE_TOOLTIP });
            };
        }
        return () => null;
    };

    return (
        <React.Fragment>
            <Flex justify="center" className={cx(styles.manageShopButtonContainer)}>
                <button
                    ref={ref}
                    type="button"
                    className={isTrialUser ? cx(styles.manageShopButtonDisabled) : cx(styles.manageShopButton)}
                    onClick={openSetupShopDialog(dispatch)}
                    onMouseEnter={isTrialUser ? showDisabledTooltip : showTooltip}
                    onMouseLeave={hideTooltip}
                >
                    <Msg k="component.webshop.productWidget.setupProduct">
                        Set up product
                    </Msg>
                </button>
            </Flex>
            <EPSeparator />
        </React.Fragment>
    );
};
