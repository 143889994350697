import { DataSite } from "../../../../../dal/model";
import type { SectionLinkInfoSaveResult } from "../dialogs/SectionLinkInfoDialog/flowTypes";
import { preparePersistentModel } from "../../../../../dal/model/utils";
import loadSiteSectionLinkWithValues from "../utils/loadSiteSectionLinkWithValues";
import updateSiteDataSaga from "../../saga/updateSiteDataSaga";
import { closeDialogGen } from "../../../../utils/saga";
import makeResolveWhenFulfilledSaga from "../../../../epics/makeResolveWhenFulfilledSaga";
import { siteDataReceiveOnlyValueActionType } from '../../../App/epics/siteData/valueActionType';
import { SECTION_LINK_INFO_SAVE } from "../actionTypes";

const updateSectionLinkSagaWorker = function* (
    site: DataSite,
    input: SectionLinkInfoSaveResult
): Generator<any, void, any> {
    const
        { sectionLinkId, data } = input,
        newSite = preparePersistentModel(new DataSite(site)), // clone site
        newSectionLink = newSite.getItemById(sectionLinkId);
    loadSiteSectionLinkWithValues(newSectionLink, data);

    yield* updateSiteDataSaga({ site: newSite });

    yield* closeDialogGen();
};

const listener = makeResolveWhenFulfilledSaga(
    siteDataReceiveOnlyValueActionType,
    SECTION_LINK_INFO_SAVE,
    updateSectionLinkSagaWorker
);

export {
    listener as default,
    updateSectionLinkSagaWorker
};
