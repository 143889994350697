import type { SiteSettingsEpicState } from "./flowTypes";
import { withSelector, when, receiveOnly, whenWithSelector } from "../../../../epics/makeCondition";
import { siteSettingsValueActionType } from "./valueActionType";
import type { PathSelectorPath } from '../../../../utils/pathSelector';
import { pathSelector } from '../../../../utils/pathSelector';
import { Lit } from '../../../../lit';
import { epicStateAppSel } from "../../../../epics/selectors";
import { UNDO_INITIAL_STATE } from "../../../../epics/undoManager/updateReasons";
import { AppState } from "../../../../redux/modules/flowTypes";

const
    currentSiteSettings = ({ current }: SiteSettingsEpicState) => current,
    SiteSettingsSelector = withSelector(siteSettingsValueActionType, currentSiteSettings),
    ROSiteSettingsSelector = receiveOnly(siteSettingsValueActionType, currentSiteSettings),
    originalSiteSettings = ({ original }: SiteSettingsEpicState) => original,
    OriginalSiteSettingsSelector = withSelector(siteSettingsValueActionType, originalSiteSettings),
    SiteSettingsSyncSelector = when(
        siteSettingsValueActionType,
        ({ epicUpdateReason }) => epicUpdateReason === UNDO_INITIAL_STATE
    ),
    SiteSettingsSelectorOnUndoInitialState = whenWithSelector(
        siteSettingsValueActionType,
        currentSiteSettings,
        ({ epicUpdateReason }) => epicUpdateReason === UNDO_INITIAL_STATE
    );

export const
    siteSettingsEpicCurrentSel = (path: PathSelectorPath = [], defaultValue: any = null) =>
        pathSelector([Lit.current, ...path], defaultValue),
    siteSettingsGeneralDataSel = (path: PathSelectorPath = [], defaultValue: any = null) =>
        siteSettingsEpicCurrentSel([Lit.generalData, ...path], defaultValue),
    siteSettingsMobileSel = (path: PathSelectorPath = [], defaultValue: any = null) => siteSettingsEpicCurrentSel([
        Lit.mobile,
        ...path,
    ], defaultValue);

export {
    currentSiteSettings,
    SiteSettingsSelector,
    ROSiteSettingsSelector,
    OriginalSiteSettingsSelector,
    SiteSettingsSyncSelector,
    SiteSettingsSelectorOnUndoInitialState
};

export const
    siteSettingsAppSel = (path: PathSelectorPath = []) => epicStateAppSel(siteSettingsValueActionType, path),
    currentSiteSettingsAppSel = (path: PathSelectorPath = []) => siteSettingsAppSel([Lit.current, ...path]),
    webshopAndProductPageIdsSel=(appState:AppState) => {
        const { webshopPageIds, featuredProductsPageIds } = currentSiteSettingsAppSel()(appState);
        return {
            webshopPageIds,
            featuredProductsPageIds
        }
    },
    currentGeneralDataSiteSettingsAppSel = (path: PathSelectorPath = []) =>
        currentSiteSettingsAppSel([Lit.generalData, ...path]);
