import { evolve, T } from "ramda";
import {
    makeCombineReducer,
    makeDefaultStateReducers,
    makeComponentBaseReducers,
    makePayloadToSetterReducer
} from "../../../../redux/makeReducer/index";
import kind from "../kind";
import * as Actions from "../actionTypes";
import saveFieldsReducer from './saveFields';
import fontReducer from './font';
import fontSizeReducer from './fontSize';
import buttonFontSizeReducer from './buttonFontSize';
import fileUploadButtonFontSizeReducer from './fileUploadButtonFontSize';
import fileUploadButtonStyleReducer from './fileUploadButtonStyle';
import fontColorReducer from './fontColor';
import buttonTextReducer from './buttonText';
import buttonStyleReducer from './buttonStyle';
import setEmailReducer from './setEmail';
import { DefaultState } from '../constants';
import { MOBILE_EDITOR_ALIGNMENT_CHANGE } from "../../../MobileViewEditor/actionTypes";
import { mobileSettingsReducer } from "../../../PropertiesPanel/view/MobileView/mobileSettingsReducer";
import type { ContactFormComponent } from "../flowTypes";
import { getIntl } from '../../../../view/intl/injectIntl';

export default makeCombineReducer({
    combineReducers: {
        ...makeComponentBaseReducers(kind),
        ...makeDefaultStateReducers(DefaultState),
    },
    handleActions: {
        [Actions.CONTACT_FORM_EDIT_FIELDS_SAVE]: saveFieldsReducer,
        [Actions.CONTACT_FORM_SUCCESS_MESSAGE_CHANGED]: makePayloadToSetterReducer('successMessage'),
        [Actions.CONTACT_FORM_FONT_CHANGED]: fontReducer,
        [Actions.CONTACT_FORM_FONT_SIZE_CHANGED]: fontSizeReducer,
        [Actions.CONTACT_FORM_SEND_BUTTON_FONT_SIZE_CHANGED]: buttonFontSizeReducer,
        [Actions.CONTACT_FORM_FILE_UPLOAD_BUTTON_FONT_SIZE_CHANGED]: fileUploadButtonFontSizeReducer,
        [Actions.CONTACT_FORM_FONT_COLOR_CHANGED]: fontColorReducer,
        [Actions.CONTACT_FORM_FONT_COLOR_CHANGED_AUTO_COLOR]: (component: ContactFormComponent, { payload }) => {
            return { ...component, styleForm: { ...component.styleForm, themeOverrideColor: payload.themeColor } };
        },
        [Actions.CONTACT_FORM_FONT_COLOR_REMOVED_AUTO_COLOR]: (component: ContactFormComponent) => {
            return { ...component, styleForm: { ...component.styleForm, themeOverrideColor: null } };
        },
        [Actions.CONTACT_FORM_CHANGE_BUTTON_TEXT]: buttonTextReducer,
        [Actions.CONTACT_FORM_CHANGE_BUTTON_STYLE]: buttonStyleReducer,
        [Actions.CONTACT_FORM_CHANGE_FILE_UPLOAD_BUTTON_STYLE]: fileUploadButtonStyleReducer,
        [Actions.CONTACT_FORM_CHANGE_BUTTON_STYLE_FOR_AUTO_COLOR]: (
            component: ContactFormComponent, { payload: buttonThemeSelected }
        ) => ({ ...component, buttonThemeSelected }),
        [Actions.CONTACT_FORM_CHANGE_FILE_UPLOAD_BUTTON_STYLE_FOR_AUTO_COLOR]: (
            component: ContactFormComponent, { payload: fuButtonThemeSelected }
        ) => ({ ...component, fuButtonThemeSelected }),
        [Actions.CONTACT_FORM_ENABLE_CAPTCHA]: (component: ContactFormComponent) => (
            { ...component, isCaptchaEnabled: true }
        ),
        [Actions.CONTACT_FORM_DISABLE_CAPTCHA]: (component: ContactFormComponent) => (
            { ...component, isCaptchaEnabled: false }
        ),
        [Actions.CAPTCHA_LOCALE_CHANGED]: (component: ContactFormComponent, { payload }) => (
            { ...component, captchaLang: payload.captchaLanguage }
        ),
        [Actions.CONTACT_FORM_SET_EMAIL]: setEmailReducer,
        [Actions.CONTACT_FORM_SET_EMAIL_PP]: (component: ContactFormComponent, { payload }) => (
            { ...component, recipientEmail: payload }
        ),
        [Actions.CONTACT_FORM_SET_SUBJECT_PP]: (component: ContactFormComponent, { payload }) => (
            { ...component, subject: payload.subject }
        ),
        [MOBILE_EDITOR_ALIGNMENT_CHANGE]: mobileSettingsReducer('align'),
        [Actions.ENABLE_MARKETING_CONSENT]: (component: ContactFormComponent) => evolve({
            formElements: {
                email: {
                    isRequired: T,
                },
            },
            isMarketingConsentEnabled: T,
            readPrivacyPolicyText: (currentText) =>
                currentText || getIntl()?.msgJoint("msg: common.readPrivacyPolicy {Read Privacy policy}")
        }, component),
        [Actions.DISABLE_MARKETING_CONSENT]: (component: ContactFormComponent) => (
            { ...component, isMarketingConsentEnabled: false }
        ),
        [Actions.MARKETING_CONSENT_CHECKBOX_TEXT_CHANGED]: (component: ContactFormComponent, { payload }) => (
            { ...component, marketingConsentCheckBoxText: payload }
        ),
    }
});
