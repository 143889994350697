import React, { useEffect, useRef } from "react";
import { BACKGROUND, SECTION, STRIP } from "../../componentKinds";

type Props = React.HTMLAttributes<HTMLDivElement> & {
    kind: string;
    backgroundImage?: string | string[];
};

const SUPPORTED_KINDS = new Set([
    BACKGROUND,
    SECTION,
    STRIP,
    // HOVERBOX, TODO: for some reason, div ref is undefined for hoverbox
]);

export const LazyBackgroundImage = ({ kind, backgroundImage, style, ...divProps }: Props) => {
    let dataBackground: string | null = null;
    if (SUPPORTED_KINDS.has(kind)) {
        if (typeof backgroundImage === "string" && backgroundImage.startsWith('url("')) {
            dataBackground = backgroundImage;
        } else if (typeof backgroundImage === "object" && backgroundImage.length && backgroundImage[0].startsWith('url("')) {
            dataBackground = backgroundImage[0];
        }
    }

    // show images for the left extender panel in workspace
    const handleContainerRef = (element: HTMLDivElement) => {
        if (element && dataBackground) {
            new IntersectionObserver(
                (entries, observer) => {
                    entries.forEach(entry => {
                        const target = entry.target;
                        if (entry.isIntersecting && target instanceof HTMLElement) {
                            target.style.backgroundImage = dataBackground;
                            observer.disconnect();
                        }
                    });
                },
                {
                    rootMargin: "10px"
                }
            ).observe(element);
        }
    };

    if (!dataBackground) {
        return <div {...divProps} style={style} />;
    }

    return (
        <div
            {...divProps}
            ref={handleContainerRef}
            style={{ ...style, backgroundImage: undefined }}
            data-background={dataBackground.split('"')[1]}
            data-background-image-lazy-load="true"
        />
    );
};
