import * as React from 'react';
import cx from 'classnames';
import styles from './FileManagerDialog.css';
import fcBodyStyles from '../../../../view/common/FileChooser/FileChooserBody/FileChooserBody.css';
import type { FmTabIdT, FileManagerDialogTabsProps } from "../types";
import { injectIntl } from "../../../../view/intl/index";
import type { MsgJointInput } from "../../../../view/intl/index";
import { FM_DIALOG_WIDTH, FM_GRID_GUTTER, FM_GRID_ROW_SIZE, FmTabId } from "../constants";
import { closeFmDialogAction, switchFmTabAction } from "../actions";
import { fcSaveAction, freeOneComSaveAction } from "../../../../redux/modules/children/fileChooser/actionCreators/index";
import { buyPaidShutterstockImageAction } from "../../paidShutterstock/actions";
import { FileManagerMode, getBrandName, siteTemplatesBrandName } from "../../constants";
import { FreeOnecom } from "../../freeOnecom/components/FreeOnecom";
import { PaidShutterstock } from "../../paidShutterstock/components/PaidShutterstock";
import { ViewModes } from "../../../../view/common/FileChooser/constants";
import FileChooser from '../../../../view/common/FileChooser/index';
import { parseIntDec } from "../../../../../utils/number";
import { ShutterstockFooter } from "../../shutterstock/components/ShutterstockFooter";
import { ShutterstockKind } from "../../shutterstock/constants";
import AppConfig from "../../../../utils/AppConfig";
import getFeatureManager from '../../../../getFeatureManager';
import { getAppConfig } from "../../../App/epics/appConfig/appConfig";
import { unsplashSaveAction } from "../../unsplash/actions";
import { Unsplash } from "../../unsplash/components/Unsplash";
import { ThirdPartyImageKind } from "../../unsplash/components/constants";

const appConfig = AppConfig(getAppConfig()),
    isShutterstockShown = getFeatureManager().isShutterstockShown(),
    isUnsplashDisabled = !getFeatureManager().isUnsplashShown();

const
    MCTA_LABEL = 'msg: common.addToPage {Add to page}',
    SCTA_LABEL = 'msg: common.cancel {Cancel}',
    FC_HEIGHT = styles.fcHeight,
    PREVIEW_WIDTH = parseIntDec(fcBodyStyles.previewWidth);

type TabCallToAction = {
    label: MsgJointInput | ((a: boolean, b: Record<string, any>) => MsgJointInput),
    handler: () => void,
};

type Tab = {
    id: string,
    title: FmTabIdT | [string, AnyMap],
    render: () => React.ReactNode,
    mcta: TabCallToAction,
    scta?: TabCallToAction,
    footerChildren?: React.ReactElement<any>,
    new?: string
}

class FileManagerDialogTabsClass extends React.Component<FileManagerDialogTabsProps> {
    static defaultProps = {
        isMultiSelect: false,
        forwardToComponent: false,
    };

    tabs: Array<Tab>;

    constructor(props: FileManagerDialogTabsProps) {
        super(props);
        const isVideoFileDialog = props.contentTypes &&
            props.contentTypes[0] &&
            props.contentTypes[0].startsWith("^video");
        const partnerName = appConfig.get("partnerName");
        const brandName = getBrandName(partnerName);
        const getVideoDialogTitle = (): FmTabIdT | [string, AnyMap] => {
            if (partnerName === siteTemplatesBrandName) {
                return 'msg: fm.dialog.tab.freeSiteTempatesVideo.title {Free videos}';
            }
            return [
                'msg: fm.dialog.tab.freeVideos.title {Free from {brand}}',
                { brand: brandName }
            ];
        };
        const getImageDialogTitle = (): FmTabIdT | [string, AnyMap] => {
            if (partnerName === siteTemplatesBrandName) {
                return 'msg: fm.dialog.tab.freeSiteTempatesImages.title {Free images}';
            }
            return 'msg: fm.dialog.tab.freeOnecom.title {Free from one.com}';
        };

        this.tabs = [
            {
                id: FmTabId.MY_IMAGES,
                title: 'msg: fm.dialog.tab.myImages.title {My images}',
                render: this.renderMyImagesTab.bind(this),
                mcta: {
                    label: MCTA_LABEL,
                    handler: this.myImagesMcta.bind(this),
                },
                scta: {
                    label: SCTA_LABEL,
                    handler: this.scta,
                }
            },
            {
                id: FmTabId.MY_VIDEOS,
                title: 'msg: fm.dialog.tab.myVideos.title {My videos}',
                render: this.renderMyVideosTab.bind(this),
                mcta: {
                    label: MCTA_LABEL,
                    handler: this.myVideosMcta.bind(this),
                },
                scta: {
                    label: SCTA_LABEL,
                    handler: this.scta,
                }
            },
            {
                id: FmTabId.FREE_ONECOM_IMAGES,
                title: isVideoFileDialog ? getVideoDialogTitle() : getImageDialogTitle(),
                render: this.renderFreeOnecomTab.bind(this),
                mcta: {
                    label: MCTA_LABEL,
                    handler: this.freeOnecomMcta.bind(this, isVideoFileDialog),
                },
                scta: {
                    label: SCTA_LABEL,
                    handler: this.scta,
                },
                footerChildren: isVideoFileDialog ?
                    <ShutterstockFooter freeVideos /> :
                    <ShutterstockFooter
                        kind={ThirdPartyImageKind.UNSPLASH}
                    />,
            },
            {
                id: FmTabId.UNSPLASH,
                title: 'msg: fm.dialog.tab.unsplash.title {Unsplash}',
                render: this.renderUnsplashTab.bind(this),
                mcta: {
                    label: MCTA_LABEL,
                    handler: this.unsplashMcta.bind(this),
                },
                scta: {
                    label: SCTA_LABEL,
                    handler: this.scta,
                },
                footerChildren: <ShutterstockFooter kind={ThirdPartyImageKind.UNSPLASH} />,
                new: 'msg: fm.dialog.tab.new.text {NEW}'
            },
        ];

        if (isShutterstockShown) {
            this.tabs.push({
                id: FmTabId.PAID_SHUTTERSTOCK,
                title: 'msg: fm.dialog.tab.paidShutterstock.title {Shutterstock}',
                render: this.renderPaidShutterstockTab.bind(this),
                mcta: {
                    label: (isSelected, { paidShutterstock }) => {
                        return isSelected ?
                            [
                                'msg: fm.dialog.tab.paidShutterstock.buyButton {Buy image ({price})}',
                                { price: paidShutterstock.imagePrice }
                            ] : MCTA_LABEL;
                    },
                    handler: this.paidShutterstockMcta.bind(this),
                },
                scta: {
                    label: SCTA_LABEL,
                    handler: this.scta,
                },
                footerChildren: <ShutterstockFooter />,
            });
        }
    }

    scta = () => {
        this.props.dispatch(closeFmDialogAction());
    };

    getActiveTab(activeTabId: string): Tab {
        const
            tab = this.tabs.find(t => t.id === activeTabId);

        if (!tab) {
            throw new Error(`Unknown FileManager tab id: ${activeTabId}`);
        }

        return tab;
    }

    switchTab(id: FmTabIdT) {
        const isVideoFileType = this.props.contentTypes && this.props.contentTypes[0] ?
            this.props.contentTypes[0].startsWith("^video") :
            false;
        if (id === this.props.state.activeTabId) return;
        this.props.dispatch(switchFmTabAction(id, isVideoFileType));
    }

    myImagesMcta() {
        const {
            onSaveAction,
            state: { isMultiSelect, selection },
            forwardToComponent,
            dispatch
        } = this.props;

        dispatch(fcSaveAction({
            onSaveAction,
            selection,
            isMultiSelect,
            forwardToComponent,
        }));
    }

    myVideosMcta() {
        const {
            onSaveAction,
            state: { isMultiSelect, selection },
            forwardToComponent,
            dispatch
        } = this.props;

        dispatch(fcSaveAction({
            onSaveAction,
            selection,
            isMultiSelect,
            forwardToComponent,
        }));
    }

    freeOnecomMcta(isVideoFileDialog) {
        const {
            onSaveAction,
            state: { selection },
            isMultiSelect,
            forwardToComponent,
            dispatch
        } = this.props;

        if (isVideoFileDialog) {
            dispatch(freeOneComSaveAction({
                onSaveAction,
                selection,
                isMultiSelect,
                forwardToComponent,
                isVideoFileDialog
            }));
        } else {
            dispatch(unsplashSaveAction({
                isMultiSelect,
                onSaveAction,
            }));
        }
    }

    paidShutterstockMcta() {
        this.props.dispatch(buyPaidShutterstockImageAction({
            onSaveAction: this.props.onSaveAction,
            isMultiSelect: this.props.isMultiSelect
        }));
    }

    unsplashMcta() {
        const {
            onSaveAction,
            isMultiSelect,
            dispatch
        } = this.props;
        dispatch(unsplashSaveAction({
            onSaveAction,
            isMultiSelect
        }));
    }

    renderTabsPane() {
        const { state: { activeTabId }, intl } = this.props;
        const isShutterstockDisabled = !getFeatureManager().isShutterstockShown();
        return (
            <div className={styles.tabsPane}>
                {this.tabs.filter(tab => {
                    if (this.props.hideMyImagesTab && tab.id === FmTabId.MY_IMAGES) {
                        return false;
                    }
                    if (this.props.hideMyVideosTab && tab.id === FmTabId.MY_VIDEOS) {
                        return false;
                    }
                    if ((
                        (isShutterstockDisabled && !this.props.hideMyImagesTab) || this.props.hideFreeOneComImagesTab
                    ) && tab.id === FmTabId.FREE_ONECOM_IMAGES) {
                        return false;
                    }
                    if ((isShutterstockDisabled || this.props.hideShutterstockImagesTab) && tab.id === FmTabId.PAID_SHUTTERSTOCK) {
                        return false;
                    }
                    if ((isUnsplashDisabled || this.props.hideMyImagesTab) && tab.id === FmTabId.UNSPLASH) {
                        return false;
                    }
                    return true;
                }).map(tab => (
                    <span
                        className={cx(
                            styles.tabsPaneTab,
                            {
                                [styles.selected]: tab.id === activeTabId
                            }
                        )}
                        onClick={() => this.switchTab(tab.id)}
                        key={tab.id}
                    >
                        <span>{intl.msgJoint(tab.title)}</span>
                    </span>
                ))}
            </div>
        );
    }

    renderMyImagesTab() {
        const
            {
                isMultiSelect = false,
                dialogHeight,
                width: propWidth = FM_DIALOG_WIDTH,
                preSelection,
                contentTypes,
                mode = FileManagerMode.FILE_MANAGER,
                maxMultiSelectValidation,
            } = this.props,
            onResourceDoubleClick = this.getActiveTab(this.props.state.activeTabId).mcta.handler,
            fcHeight = dialogHeight - 253,
            width = propWidth && propWidth - PREVIEW_WIDTH;

        return (
            <FileChooser
                isMultiSelect={isMultiSelect}
                preSelection={preSelection}
                hideUploadPane
                listTableHeight={fcHeight}
                showPreview
                width={width}
                activeViewMode={ViewModes.GRID}
                gridHeight={FC_HEIGHT}
                gridRowSize={FM_GRID_ROW_SIZE}
                gridGutter={FM_GRID_GUTTER}
                contentTypes={contentTypes}
                onResourceDoubleClick={onResourceDoubleClick}
                theme={{
                    container: styles.fc,
                    headerContainer: styles.fcHeader,
                    bredCrumbsPaneContainer: styles.breadCrumbsPane,
                    gridContainer: styles.fcGrid
                }}
                mode={mode}
                maxMultiSelectValidation={maxMultiSelectValidation}
                headlessMode={this.props.headlessMode}
                onSaveAction={this.props.onSaveAction}
                forwardToComponent={this.props.forwardToComponent}
            />
        );
    }

    renderMyVideosTab() {
        const
            {
                isMultiSelect = false,
                dialogHeight,
                width: propWidth = FM_DIALOG_WIDTH,
                preSelection,
                contentTypes,
                mode = FileManagerMode.FILE_MANAGER,
                maxMultiSelectValidation,
            } = this.props,
            onResourceDoubleClick = this.getActiveTab(this.props.state.activeTabId).mcta.handler,
            fcHeight = dialogHeight - 253,
            width = propWidth && propWidth - PREVIEW_WIDTH;

        return (
            <FileChooser
                isMultiSelect={isMultiSelect}
                preSelection={preSelection}
                hideUploadPane
                listTableHeight={fcHeight}
                showPreview
                width={width}
                activeViewMode={ViewModes.GRID}
                gridHeight={FC_HEIGHT}
                gridRowSize={FM_GRID_ROW_SIZE}
                gridGutter={FM_GRID_GUTTER}
                contentTypes={contentTypes}
                onResourceDoubleClick={onResourceDoubleClick}
                theme={{
                    container: styles.fc,
                    headerContainer: styles.fcHeader,
                    bredCrumbsPaneContainer: styles.breadCrumbsPane,
                    gridContainer: styles.fcGrid
                }}
                mode={mode}
                maxMultiSelectValidation={maxMultiSelectValidation}
                headlessMode={this.props.headlessMode}
                onSaveAction={this.props.onSaveAction}
                forwardToComponent={this.props.forwardToComponent}
            />
        );
    }

    renderFreeOnecomTab() {
        const {
            isMultiSelect,
            // @ts-ignore
            state: { [ShutterstockKind.FREE]: freeOnecomState },
            dispatch,
            subscriptionType,
            subscriptionMetadata,
            quotaConf
        } = this.props;

        const isVideoFileDialog = this.props.contentTypes && this.props.contentTypes[0] && this.props.contentTypes[0].startsWith("^video");

        return (
            <FreeOnecom
                isMultiSelect={isMultiSelect}
                state={freeOnecomState}
                // @ts-ignore
                isVideoFileDialog={isVideoFileDialog}
                subscriptionType={subscriptionType}
                subscriptionMetadata={subscriptionMetadata}
                dispatch={dispatch}
                quotaConf={quotaConf}
            />
        );
    }

    renderUnsplashTab() {
        const {
            isMultiSelect,
            // @ts-ignore
            state: { [ThirdPartyImageKind.UNSPLASH]: unsplashState },
            dispatch,
            subscriptionType,
            quotaConf
        } = this.props;

        return (
            <Unsplash
                isMultiSelect={isMultiSelect}
                state={unsplashState}
                subscriptionType={subscriptionType}
                dispatch={dispatch}
                quotaConf={quotaConf}
            />
        );
    }

    renderPaidShutterstockTab() {
        const {
            isMultiSelect,
            state: { paidShutterstock },
            dispatch,
            subscriptionType
        } = this.props;

        return (
            <PaidShutterstock
                isMultiSelect={isMultiSelect}
                state={paidShutterstock}
                subscriptionType={subscriptionType}
                dispatch={dispatch}
                onSaveAction={this.props.onSaveAction}
            />
        );
    }

    render() {
        return (
            <React.Fragment>
                {this.renderTabsPane()}
                {this.getActiveTab(this.props.state.activeTabId).render()}
            </React.Fragment>
        );
    }
}

// @ts-ignore
export const FileManagerDialogTabsCom = injectIntl(FileManagerDialogTabsClass, { forwardRef: true });
