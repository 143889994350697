import { fileManagerMiddlewares } from "../../components/FileManager/fileManagerMiddlewares";
import { demoMiddleware } from '../../../demo/modules/demoMiddleware';
import { isWsbDemo } from '../../debug/isWsbDemo';
import { preferencesMiddleware } from '../../components/Preferences/preferencesMiddleware';
import { whatsNewMiddleware } from '../../components/WhatsNew/whatsNewMiddleware';
import {
    documentConfigDialogMiddleware,
} from "../../components/oneweb/Document/configurationDialog/middleware/documentConfigDialogMiddleware";
import { demoLoginMiddleware } from "../../../demo/modules/login/demoLoginMiddleware";
import { trialImportAvailabilityMiddleware } from "../../../demo/modules/import/trialImportAvailabilityMiddleware";
import { presentationalMiddlewares } from "../../components/presentational/middlewares";
import { missingAssetsMiddleware } from "../../components/MissingAssets/missingAssetsMiddleware";
import { imageSliderMiddleware } from "../../components/oneweb/ImageSlider/middleware/imageSliderMiddleware";
import { galleryMiddleware } from "../../components/oneweb/Gallery/middleware/galleryMiddleware";
import { delayMiddleware } from "../../components/Workspace/delayMiddleware";
import { setOrReplaceImageMiddleware } from "../../components/oneweb/Image/middleware/imageMiddleware";
import { videoTutorialMiddleware } from "../../components/TopBar/view/TutorialVideoDialog/videoTutorialMiddleware";
import { linkChooserMiddleware } from "../../view/common/dialogs/LinkChooserDialog/linkChooserMiddleware";
import { redirectToDashboard } from '../../components/oneweb/ContactForm/middlewares/redirectToDashboard';
import { imageEditorMiddleware } from "../../components/ImageEditor/middleware";
import { fontsMiddleware } from "../../components/Fonts/fontsMiddleware";

const moduleMiddlewares = [
    linkChooserMiddleware,
    ...fileManagerMiddlewares,
    preferencesMiddleware,
    whatsNewMiddleware,
    trialImportAvailabilityMiddleware,
    documentConfigDialogMiddleware,
    demoLoginMiddleware, // TODO: put under isWsbDemo() (this fails in tests)
    ...presentationalMiddlewares,
    missingAssetsMiddleware,
    imageSliderMiddleware,
    setOrReplaceImageMiddleware,
    galleryMiddleware,
    delayMiddleware,
    videoTutorialMiddleware,
    redirectToDashboard,
    imageEditorMiddleware,
    fontsMiddleware
];

if (isWsbDemo()) {
    moduleMiddlewares.push(demoMiddleware);
}

export { moduleMiddlewares };
