import ComponentTypes from '../../src/view/oneweb/registry/ComponentTypes';

const
    OldComponentTypes = {
        TEMPLATE: 'web.data.components.Template',
        BLOCK: 'web.data.components.Block',
        IMAGE: 'web.data.components.resources.Image',
        BUTTON: 'web.data.components.Buttons',
        MENU: 'web.data.components.Menu',
        GALLERY: 'web.data.components.Gallery',
        TEXT: 'web.data.components.Text',
        VIDEO: 'web.data.components.Video',
        VIDEO_FILE: 'web.data.components.VideoFile',
        FACEBOOKPAGE: 'web.data.components.FacebookPage',
        FACEBOOKPAGELIKE: 'web.data.components.widgets.FacebookLikeBox',
        CONTACTFORM: 'web.data.components.ContactForm',
        IMAGESLIDER: 'web.data.components.ImageSlider',
        TABLE: 'web.data.components.Table',
        DOCUMENT: 'web.data.components.GoogleDocsViewer',
        SHAREBUTTONS: 'web.data.components.ShareButtons',
        CODE: 'web.data.components.Code',
        WEBSHOP: 'web.data.components.WebShop',
        WEBSHOP_POLICIES: ComponentTypes.WEBSHOP_POLICIES,
        WEBSHOP_PAYMENT_METHODS: ComponentTypes.WEBSHOP_PAYMENT_METHODS,
        INSTAGRAM_GALLERY: 'web.data.components.InstagramGallery',
        FACEBOOK_FEED_GALLERY: 'web.data.components.FacebookFeedGallery',
        [ComponentTypes.SOCIAL_LINKS]: ComponentTypes.SOCIAL_LINKS,
        [ComponentTypes.LOGO]: ComponentTypes.LOGO,
        [ComponentTypes.EMAIL_KIND]: ComponentTypes.EMAIL_KIND,
        [ComponentTypes.PHONE_KIND]: ComponentTypes.PHONE_KIND,
        [ComponentTypes.ADDRESS_KIND]: ComponentTypes.ADDRESS_KIND,
        [ComponentTypes.SVG_KIND]: ComponentTypes.SVG_KIND,
        [ComponentTypes.SECTION]: ComponentTypes.SECTION,
        [ComponentTypes.HOVERBOX]: ComponentTypes.HOVERBOX,
        FEATURED_PRODUCTS: 'web.data.components.FeaturedProducts',
        [ComponentTypes.OPENING_HOURS]: ComponentTypes.OPENING_HOURS,
        [ComponentTypes.PRODUCT_WIDGET]: ComponentTypes.PRODUCT_WIDGET,
        [ComponentTypes.GOOGLE_REVIEWS]: ComponentTypes.GOOGLE_REVIEWS,
        [ComponentTypes.WIDGETS_TWITTER]: ComponentTypes.WIDGETS_TWITTER,
        [ComponentTypes.WIDGETS_SPOTIFY]: ComponentTypes.WIDGETS_SPOTIFY,
        [ComponentTypes.WIDGETS_PINTEREST]: ComponentTypes.WIDGETS_PINTEREST,
        [ComponentTypes.WIDGETS_VIMEO]: ComponentTypes.WIDGETS_VIMEO,
        [ComponentTypes.WIDGETS_GOOGLEMAPS]: ComponentTypes.WIDGETS_GOOGLEMAPS,
        [ComponentTypes.WIDGETS_INSTAGRAM]: ComponentTypes.WIDGETS_INSTAGRAM,
        [ComponentTypes.WIDGETS_FACEBOOK]: ComponentTypes.WIDGETS_FACEBOOK,
        [ComponentTypes.WIDGETS_SOUNDCLOUD]: ComponentTypes.WIDGETS_SOUNDCLOUD,
        [ComponentTypes.WIDGETS_TIKTOK]: ComponentTypes.WIDGETS_TIKTOK,
        [ComponentTypes.WIDGETS_EVENTBRITE]: ComponentTypes.WIDGETS_EVENTBRITE,
        [ComponentTypes.WIDGETS_BINGMAPS]: ComponentTypes.WIDGETS_BINGMAPS,
        [ComponentTypes.WIDGETS_GOFUNDME]: ComponentTypes.WIDGETS_GOFUNDME,
        [ComponentTypes.WIDGETS_MAILCHIMP]: ComponentTypes.WIDGETS_MAILCHIMP,
        [ComponentTypes.WIDGETS_PAYPAL]: ComponentTypes.WIDGETS_PAYPAL,
        [ComponentTypes.WIDGETS_BOOKINGS]: ComponentTypes.WIDGETS_BOOKINGS,
    },
    NewComponentTypes = {
        [OldComponentTypes.TEMPLATE]: ComponentTypes.TEMPLATE,
        [OldComponentTypes.BLOCK]: ComponentTypes.BACKGROUND,
        [OldComponentTypes.IMAGE]: ComponentTypes.IMAGE,
        [OldComponentTypes.BUTTON]: ComponentTypes.BUTTON,
        [OldComponentTypes.MENU]: ComponentTypes.MENU,
        [OldComponentTypes.GALLERY]: ComponentTypes.GALLERY,
        [OldComponentTypes.TEXT]: ComponentTypes.TEXT,
        [OldComponentTypes.VIDEO]: ComponentTypes.VIDEO,
        [OldComponentTypes.VIDEO_FILE]: ComponentTypes.VIDEO_FILE,
        [ComponentTypes.VIDEO_FILE]: ComponentTypes.VIDEO_FILE,
        [OldComponentTypes.FACEBOOKPAGE]: ComponentTypes.FACEBOOKPAGE,
        [OldComponentTypes.FACEBOOKPAGELIKE]: ComponentTypes.FACEBOOKPAGE, // TODO WBTGEN-2574
        [OldComponentTypes.CONTACTFORM]: ComponentTypes.CONTACTFORM,
        [OldComponentTypes.IMAGESLIDER]: ComponentTypes.IMAGESLIDER,
        [OldComponentTypes.TABLE]: ComponentTypes.TABLE,
        [OldComponentTypes.DOCUMENT]: ComponentTypes.DOCUMENT,
        [OldComponentTypes.SHAREBUTTONS]: ComponentTypes.SHAREBUTTONS,
        [OldComponentTypes.CODE]: ComponentTypes.CODE,
        [OldComponentTypes.WEBSHOP]: ComponentTypes.WEBSHOP,
        [ComponentTypes.WEBSHOP_POLICIES]: ComponentTypes.WEBSHOP_POLICIES,
        [ComponentTypes.WEBSHOP_PAYMENT_METHODS]: ComponentTypes.WEBSHOP_PAYMENT_METHODS,
        [OldComponentTypes.INSTAGRAM_GALLERY]: ComponentTypes.INSTAGRAM_GALLERY,
        [ComponentTypes.INSTAGRAM_GALLERY]: ComponentTypes.INSTAGRAM_GALLERY,
        [OldComponentTypes.FACEBOOK_FEED_GALLERY]: ComponentTypes.FACEBOOK_FEED_GALLERY,
        [ComponentTypes.SOCIAL_LINKS]: ComponentTypes.SOCIAL_LINKS,
        [ComponentTypes.LOGO]: ComponentTypes.LOGO,
        [ComponentTypes.EMAIL_KIND]: ComponentTypes.EMAIL_KIND,
        [ComponentTypes.PHONE_KIND]: ComponentTypes.PHONE_KIND,
        [ComponentTypes.ADDRESS_KIND]: ComponentTypes.ADDRESS_KIND,
        [ComponentTypes.SVG_KIND]: ComponentTypes.SVG_KIND,
        [ComponentTypes.SECTION]: ComponentTypes.SECTION,
        [ComponentTypes.HOVERBOX]: ComponentTypes.HOVERBOX,
        [OldComponentTypes.FEATURED_PRODUCTS]: ComponentTypes.FEATURED_PRODUCTS_KIND,
        [ComponentTypes.GOOGLE_REVIEWS]: ComponentTypes.GOOGLE_REVIEWS,
        [ComponentTypes.OPENING_HOURS]: ComponentTypes.OPENING_HOURS,
        [ComponentTypes.PRODUCT_WIDGET]: ComponentTypes.PRODUCT_WIDGET,
        [ComponentTypes.WIDGETS_TWITTER]: ComponentTypes.WIDGETS_TWITTER,
        [ComponentTypes.WIDGETS_SPOTIFY]: ComponentTypes.WIDGETS_SPOTIFY,
        [ComponentTypes.WIDGETS_PINTEREST]: ComponentTypes.WIDGETS_PINTEREST,
        [ComponentTypes.WIDGETS_VIMEO]: ComponentTypes.WIDGETS_VIMEO,
        [ComponentTypes.WIDGETS_GOOGLEMAPS]: ComponentTypes.WIDGETS_GOOGLEMAPS,
        [ComponentTypes.WIDGETS_INSTAGRAM]: ComponentTypes.WIDGETS_INSTAGRAM,
        [ComponentTypes.WIDGETS_FACEBOOK]: ComponentTypes.WIDGETS_FACEBOOK,
        [ComponentTypes.WIDGETS_SOUNDCLOUD]: ComponentTypes.WIDGETS_SOUNDCLOUD,
        [ComponentTypes.WIDGETS_TIKTOK]: ComponentTypes.WIDGETS_TIKTOK,
        [ComponentTypes.WIDGETS_EVENTBRITE]: ComponentTypes.WIDGETS_EVENTBRITE,
        [ComponentTypes.WIDGETS_BINGMAPS]: ComponentTypes.WIDGETS_BINGMAPS,
        [ComponentTypes.WIDGETS_GOFUNDME]: ComponentTypes.WIDGETS_GOFUNDME,
        [ComponentTypes.WIDGETS_MAILCHIMP]: ComponentTypes.WIDGETS_MAILCHIMP,
        [ComponentTypes.WIDGETS_PAYPAL]: ComponentTypes.WIDGETS_PAYPAL,
        [ComponentTypes.WIDGETS_BOOKINGS]: ComponentTypes.WIDGETS_BOOKINGS,
    },
    defaultComponentType = ComponentTypes.BACKGROUND,
    defaultOldComponentType = OldComponentTypes.BLOCK,
    getOldComponentType = (kind: string): string => {
        const key = Object.keys(NewComponentTypes).filter(key => NewComponentTypes[key] === kind);
        return key[0];
    },
    isBlockPageItemType = (type: string): boolean => type === OldComponentTypes.BLOCK;

export {
    OldComponentTypes,
    NewComponentTypes,
    NewComponentTypes as default,
    defaultComponentType,
    defaultOldComponentType,
    getOldComponentType,
    isBlockPageItemType,
};
